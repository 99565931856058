<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon-gift">
      <g id="Vector">
        <path
          d="M12.8405 8.03903H12.6078C12.4983 8.03457 12.3912 8.00469 12.2952 7.95176C12.1991 7.89882 12.1167 7.82428 12.0544 7.73402C11.9922 7.64376 11.9517 7.54025 11.9363 7.43168C11.9209 7.3231 11.931 7.21243 11.9657 7.10841C12.2914 6.28948 12.5892 5.45192 12.8312 4.63299C13.0359 4.00017 13.8362 2.01797 15.8464 2.01797C16.6298 1.99286 17.3911 2.27958 17.9634 2.8152C18.5357 3.35082 18.8721 4.09158 18.8988 4.87494C18.8988 6.67103 16.8607 7.40621 16.1069 7.66678C15.0412 7.94487 13.9414 8.0702 12.8405 8.03903ZM15.837 3.38597C14.7668 3.38597 14.2829 4.62368 14.1619 5.00523C13.9944 5.54499 13.8176 6.08474 13.6222 6.6245C14.2894 6.62026 14.9529 6.52635 15.5951 6.34531C16.1255 6.15919 17.4563 5.65666 17.4563 4.84703C17.4276 4.4414 17.243 4.06263 16.9411 3.79022C16.6392 3.51781 16.2435 3.37292 15.837 3.38597Z"
          fill="#E6E6E6"
        />
        <path
          d="M12.348 8.03967C11.271 8.05864 10.1966 7.92708 9.15603 7.64881C6.83881 6.89502 6.3642 5.71314 6.3642 4.85698C6.36809 4.49536 6.44513 4.13827 6.59066 3.80721C6.7362 3.47615 6.94724 3.17796 7.21106 2.93061C7.79771 2.34044 8.59379 2.00595 9.42591 2C10.9893 2 11.7804 3.46106 12.3666 4.50335C12.7476 5.35777 13.0589 6.24164 13.2973 7.14628C13.3261 7.24894 13.331 7.35684 13.3117 7.4617C13.2923 7.56656 13.2492 7.66558 13.1856 7.75118C13.1239 7.83762 13.0431 7.90864 12.9495 7.95868C12.8559 8.00873 12.7519 8.03644 12.6458 8.03967H12.348ZM9.32355 3.38661C8.88162 3.40565 8.46443 3.59588 8.16028 3.91706C8.02763 4.03404 7.92037 4.17697 7.84514 4.33704C7.76991 4.4971 7.72831 4.67089 7.72289 4.84767C7.72289 5.41535 8.41155 5.96441 9.58412 6.34596C10.2683 6.52281 10.9714 6.61655 11.678 6.62514C11.5275 6.12955 11.344 5.64455 11.1289 5.17339L11.678 4.83837L11.0731 5.17339C10.5333 4.14041 10.068 3.40522 9.35146 3.38661H9.32355Z"
          fill="#E6E6E6"
        />
        <path
          d="M21.2163 10.3645V9.20124C21.2163 8.5225 20.9467 7.87156 20.4668 7.39162C19.9868 6.91168 19.3359 6.64205 18.6571 6.64205H6.55918C5.88045 6.64205 5.22951 6.91168 4.74957 7.39162C4.26963 7.87156 4 8.5225 4 9.20124V10.3645H21.2163Z"
          fill="#E6E6E6"
        />
        <path
          d="M13.3067 11.7631V21.9998H18.6577C19.3365 21.9998 19.9874 21.7302 20.4674 21.2503C20.9473 20.7703 21.2169 20.1194 21.2169 19.4407V11.7631H13.3067Z"
          fill="#E6E6E6"
        />
        <path
          d="M11.9102 11.7631H4V19.4407C4 20.1194 4.26963 20.7703 4.74957 21.2503C5.22951 21.7302 5.88045 21.9998 6.55918 21.9998H11.9102V11.7631Z"
          fill="#E6E6E6"
        />
      </g>
    </g>
  </svg>
</template>
