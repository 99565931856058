<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8261 11.331C17.9816 11.4137 19.129 11.084 20.0645 10.4005C21.3107 9.16263 21.3107 5.13325 20.0645 3.9218C18.8183 2.71035 14.7988 2.67524 13.5877 3.9218C12.3766 5.16836 12.3414 9.18897 13.5877 10.4005C14.5231 11.084 15.6706 11.4137 16.8261 11.331Z"
      fill="#E6E6E6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.93468 3.9218C2.68846 5.15959 2.68846 9.18897 3.93468 10.4005C4.87014 11.084 6.01757 11.4137 7.1731 11.331C8.32864 11.4137 9.47607 11.084 10.4115 10.4005C11.6577 9.16263 11.6577 5.13325 10.4115 3.9218C9.1653 2.71035 5.17213 2.67524 3.93468 3.9218ZM3.93466 13.5783C2.68845 14.8161 2.68845 18.8455 3.93466 20.057C4.87012 20.7405 6.01756 21.0703 7.17308 20.9875C8.32862 21.0703 9.47605 20.7405 10.4115 20.057C11.6577 18.8191 11.6577 14.7898 10.4115 13.5783C9.16528 12.3669 5.17211 12.3318 3.93466 13.5783ZM13.5884 20.057C12.3423 18.8455 12.3423 14.8161 13.5884 13.5783C14.8259 12.3318 18.819 12.3669 20.0653 13.5783C21.3116 14.7898 21.3116 18.8191 20.0653 20.057C19.1299 20.7405 17.9824 21.0703 16.8269 20.9875C15.6713 21.0703 14.524 20.7405 13.5884 20.057Z"
      fill="#E6E6E6"
    />
  </svg>
</template>
