<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9437 18.0187C12.9437 16.6633 13.4715 15.3887 14.4301 14.4301C15.3887 13.4715 16.6629 12.9437 18.0187 12.9437C19.1316 12.9437 20.1898 13.3 21.0637 13.9578C21.1121 13.25 21.1422 12.4582 21.1434 11.5719C21.141 9.34258 20.9539 7.71016 20.7543 6.51055C20.4051 4.53398 18.6098 2.73867 16.6332 2.38945C15.4336 2.18945 13.8012 2.00234 11.5715 2C9.34219 2.00234 7.70937 2.18945 6.50977 2.38906C4.53359 2.73828 2.73828 4.53359 2.38906 6.51016C2.18945 7.70976 2.00273 9.34219 2 11.5715C2.00273 13.8008 2.18945 15.4336 2.38906 16.6332C2.73828 18.6098 4.53359 20.4051 6.51016 20.7543C7.70976 20.9539 9.34219 21.1406 11.5719 21.1434C12.4582 21.1422 13.25 21.1121 13.9578 21.0637C13.3 20.1898 12.9437 19.132 12.9437 18.0187ZM6.84219 6.62578H16.3012C16.9129 6.62578 17.409 7.12187 17.409 7.73359C17.409 8.34531 16.9129 8.84141 16.3012 8.84141H6.84219C6.23047 8.84141 5.73437 8.34531 5.73437 7.73359C5.73437 7.12187 6.23047 6.62578 6.84219 6.62578ZM6.84219 13.4676C6.23047 13.4676 5.73437 12.9715 5.73437 12.3598C5.73437 11.748 6.23047 11.252 6.84219 11.252H12.3949C13.0066 11.252 13.5027 11.748 13.5027 12.3598C13.5027 12.9715 13.0066 13.4676 12.3949 13.4676H6.84219Z"
      fill="#E6E6E6"
    />
    <path
      d="M18.0188 14.0375C15.82 14.0375 14.0376 15.8199 14.0376 18.0187C14.0376 20.2176 15.82 22 18.0188 22C20.2177 22 22.0001 20.2176 22.0001 18.0187C22.0001 15.8199 20.2177 14.0375 18.0188 14.0375ZM18.761 19.9941C18.761 20.4039 18.4286 20.7363 18.0188 20.7363C17.6091 20.7363 17.2767 20.4039 17.2767 19.9941V18.0359C17.2767 17.6261 17.6091 17.2937 18.0188 17.2937C18.4286 17.2937 18.761 17.6261 18.761 18.0359V19.9941ZM18.7306 16.4472C18.7032 16.6019 18.5626 16.7426 18.4079 16.7699C18.3142 16.7855 18.186 16.8 18.0114 16.8004C17.8368 16.8004 17.7091 16.7855 17.6149 16.7699C17.4603 16.7426 17.3196 16.6019 17.2923 16.4472C17.2767 16.3535 17.2622 16.2254 17.2618 16.0508C17.2618 15.8761 17.2767 15.7484 17.2923 15.6543C17.3196 15.4996 17.4603 15.359 17.6149 15.3316C17.7087 15.316 17.8368 15.3015 18.0114 15.3011C18.186 15.3011 18.3138 15.316 18.4079 15.3316C18.5626 15.359 18.7032 15.4996 18.7306 15.6543C18.7462 15.748 18.7606 15.8761 18.761 16.0508C18.761 16.2254 18.7462 16.3531 18.7306 16.4472Z"
      fill="#E6E6E6"
    />
  </svg>
</template>
